import banner1 from "../assets/Banner/banner1.jpg"
import banner2 from "../assets/Banner/banner2.jpg"
import banner3 from "../assets/Banner/banner3.jpg"

export const Banner =[
    {
        id:1,
        imag:banner1
    },
    {
        id:2,
        imag:banner2
    },
    {
        id:3,
        imag:banner3
    },
]